import request from "./request";

export function getSupplier(params){
    return request({
        url: 'purchasement/getSupplier',
        method: 'post',
        data: params
    });
}

export function getProduct(params){
    return request({
        url: 'purchasement/getProduct',
        method: 'post',
        data: params
    });
}

export function getWareHouse(params){
    return request({
        url: 'purchasement/getWareHouse',
        method: 'post',
        data: params
    });
}

export function getShelf(params){
    return request({
        url: 'purchasement/getShelf',
        method: 'post',
        data: params
    });
}


export function getProductSku(params){
    return request({
        url: 'purchasement/getProductSku',
        method: 'post',
        data: params
    });
}

export function addPurchasement(params){
    return request({
        url: 'purchasement/addPurchasement',
        method: 'post',
        data: params
    });
}

export function getPurchasementList(params){
    return request({
        url: 'purchasement/getPurchasementList',
        method: 'post',
        data: params
    });
}

export function getPurchasement(params){
    return request({
        url: 'purchasement/getPurchasement',
        method: 'post',
        data: params
    });
}

export function updatePurchasement(params){
    return request({
        url: 'purchasement/updatePurchasement',
        method: 'post',
        data: params
    });
}

export function deletePurchasement(params){
    return request({
        url: 'purchasement/deletePurchasement',
        method: 'post',
        data: params
    });
}

export function getPurchasementDetail(params){
    return request({
        url: 'purchasement/getPurchasementDetail',
        method: 'post',
        data: params
    });
}

export function receivePurchasement(params){
    return request({
        url: 'purchasement/receivePurchasement',
        method: 'post',
        data: params
    });
}

export function updatePurchasementStatus(params){
    return request({
        url: 'purchasement/updatePurchasementStatus',
        method: 'post',
        data: params
    });
}

export function getPurchasementLedger(params){
    return request({
        url: 'purchasement/getPurchasementLedger',
        method: 'post',
        data: params
    });
}

export function getAliProduct(params){
    return request({
        url: 'purchasement/getAliProduct',
        method: 'post',
        data: params
    });
}

export function getRemoteAliProduct(params){
    return request({
        url: 'purchasement/getRemoteAliProduct',
        method: 'post',
        data: params
    });
}
